<template>
  <div class="video--field" :class="{ 'has-error': validation.hasError('videoLink') }">
    <span class="left--text">{{ index + 1 }}. </span>
    <input
      name="videos[]"
      :value="videoLink"
      ref="videoLinkField"
      @input="inputVideo"
      :placeholder="$t('addProject.placeholder.video')"
      class="basic--input"
      type="text"
    />
    <img
      src="@/assets/img/icons/trash.svg"
      v-show="length > 0"
      @click="removeVideo"
      class="trash--button"
    />
    <span class="val-error" v-if="validation.hasError('videoLink')">{{
      validation.firstError('videoLink')
    }}</span>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import CheckYoutubeUrlClass from '@/validations/checkYoutubeUrl';

export default {
  name: 'videoField',
  props: ['videoLink', 'length', 'index'],
  validators: {
    videoLink: {
      validator(value) {
        const checkYoutubeUrl = new CheckYoutubeUrlClass(value, this.$i18n);
        return Validator.value(value)
          .required(this.$i18n.t('errors.video.required'))
          .custom(checkYoutubeUrl.isValidFunc);
      },
    },
  },
  methods: {
    inputVideo($event) {
      this.$store.commit('listingForm/sect2/detail/INPUT_VIDEO', {
        input: $event.target.value,
        index: this.index,
      });
    },
    removeVideo() {
      this.$store.commit('listingForm/sect2/detail/REMOVE_VIDEO', this.index);
    },
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped></style>
